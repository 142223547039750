import React from 'react';

import { TranslationService } from '@asteria/language';
import useConfig from '@asteria/utils-hooks/useConfig';

export function useFlow(type, key, callback) {
	return useConfig('integrations', {
		callback: React.useCallback(
			(response) => {
				const object = (response ?? []).find(
					(object) => object?.type === type && object?.key === key,
				);

				if (callback) {
					return callback(object);
				}

				return object;
			},
			[callback, key, type],
		),
	});
}

export function useIntegrationName(type, key) {
	const name = useFlow(
		type,
		key,
		React.useCallback((object) => object?.name, []),
	);

	return TranslationService.get(
		[
			'integration.title',
			`integration.title.${key}`,
			`integration.title.${type}.${key}`,
			`integration.${key}.title`,
			`integration.${type}.${key}.title`,
		],
		name,
	);
}
