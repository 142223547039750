import React from 'react';

import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import Group from '@asteria/component-core/group';
import { Title } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import * as AccountStore from '@asteria/datalayer/stores/accounts';
import * as AppStore from '@asteria/datalayer/stores/app';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useModalActions } from '../../../../components/modal-collection';
import { CreditSettingsModal } from '../../../../modals';

import '../../styles.scss';

const DEFAULT_VALUES = [50_000, 100_000, 500_000, 1_000_000];

const SimulationChip = React.memo((props) => {
	const { value, onCreditUpdate } = props;

	const currency = useSelector(AppStore.selectors.currency);
	const active = useSelector(
		(store) => AccountStore.selectors.dynamicCredit(store) === value,
	);

	const onClick = React.useCallback(
		() => onCreditUpdate?.(value),
		[onCreditUpdate, value],
	);

	return (
		<Chip
			size="sm"
			label={TranslationService.get(
				[
					'financial.credit.simulation.example.label',
					`financial.credit.simulation.example.${currency}.label`,
				],
				'{{ value | number:false:false:true }} {{ currency }}',
				{ value, currency },
			)}
			onClick={onClick}
			active={active}
		/>
	);
});

SimulationChip.displayName = 'SimulationChip';
SimulationChip.propTypes = {
	value: PropTypes.number,
	onCreditUpdate: PropTypes.func,
};

const SectionHeader = React.memo((props) => {
	const { onAction } = props;

	const { open } = useModalActions();

	const { id } = useParams();

	const onBack = React.useCallback(
		() => onAction?.('redirect', '/financial'),
		[onAction],
	);

	const onCreditUpdate = React.useCallback(
		(value) => onAction?.('credit:set', value),
		[onAction],
	);

	const onSettingsClick = React.useCallback(() => {
		open(<CreditSettingsModal />, { simulationId: id, redirect: true });
	}, [id, open]);

	return (
		<Group
			className={cn(
				'asteria-component__financial-page-section',
				'asteria--variant-header',
			)}
			direction="horizontal"
			verticalAlign="top"
			horizontalAlign="space-between"
		>
			<Group className="asteria-component__financial-page-navigation">
				<Group
					className="asteria-component__financial-page-navigation-back"
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="left"
				>
					<Button
						icon="action-arrow-left"
						iconSize="lg"
						variant="tertiary"
						size="lg"
						onClick={onBack}
					/>

					<Title size="sm">
						{TranslationService.get([
							'financial.navigation.back.level.-1.label',
							'financial.page.navigation.title',
							'financial.page.credit.navigation.title',
						])}
					</Title>
				</Group>

				<Group
					className={cn(
						'asteria-component__financial-page-simulations',
						'asteria-component__chip-group',
					)}
					direction="horizontal"
					verticalAlign="center"
					horizontalAlign="left"
				>
					{DEFAULT_VALUES.map((value) => (
						<SimulationChip
							key={value}
							value={value}
							onCreditUpdate={onCreditUpdate}
						/>
					))}
				</Group>
			</Group>
			<div className="asteria-component__financial-page-actions">
				{id ? <Button icon="cog" onClick={onSettingsClick} /> : null}
			</div>
		</Group>
	);
});

SectionHeader.displayName = 'SectionHeader';
SectionHeader.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default SectionHeader;
