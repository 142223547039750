import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Step, { Content, Footer, Header } from '../../components/Step';

import IntegrationErrorBox from './box';
import IntegrationErrorBoxContent from './box-content';
import IntegrationErrorFeedback from './feedback';
import { useAnalytics } from './hooks';

import './styles.scss';

const IntegrationError = (props) => {
	const { className, onAction, onSubmit, onClose, type } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const _id = useSelector(IntegrationStore.selectors.navigation._id);
	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, _id),
	);

	const analyticsFlow = useAnalytics(integration);

	const $type = type ?? integration?.type;

	const step = `${$type}.error`;

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, onClose],
	);

	const abort = React.useCallback(
		(event) => {
			const _id = IntegrationStore.selectors.navigation._id(
				store.getState(),
			);
			const integration = IntegrationStore.selectors.integration(
				store.getState(),
				_id,
			);

			onSubmit?.('integrations:delete', integration);

			Analytics.endFlow(analyticsFlow);
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event);
		},
		[analyticsFlow, dispatch, onClose, onSubmit, store],
	);

	const back = React.useCallback(() => {
		const _id = IntegrationStore.selectors.navigation._id(store.getState());
		const integration = IntegrationStore.selectors.integration(
			store.getState(),
			_id,
		);

		onSubmit?.('integrations:delete', integration);

		Analytics.endFlow(analyticsFlow);

		dispatch(
			IntegrationStore.navigation.back(
				JSON.parse(JSON.stringify(integration)),
			),
		);
	}, [store, onSubmit, analyticsFlow, dispatch]);

	const integrationName = TranslationService.getV2([
		'integration.title',
		`integration.title.${integration?.key}`,
	]);

	return (
		<Step
			className={cn(
				'asteria-component__onboarding-generic__error',
				{
					[`asteria-component__onboarding-generic__error--type-${$type}`]:
						$type,
				},
				className,
			)}
			step={step}
		>
			<Header onBack={back} onClose={close}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${integration?.type}.add.title`,
						`integrations.${integration?.key}.add.title`,
						`integrations.${integration?.type}.${integration?.key}.add.title`,
						`integrations.error.title`,
						`integrations.${integration?.type}.error.title`,
						`integrations.${integration?.key}.error.title`,
						`integrations.${integration?.type}.${integration?.key}.error.title`,
					],
					undefined,
					{ integration: integration, name: integrationName },
				)}
			</Header>
			<Content step={step}>
				<IntegrationErrorBox
					type={$type}
					integration={integration}
					integrationName={integrationName}
				>
					<IntegrationErrorBoxContent
						type={$type}
						integrationName={integrationName}
						integration={integration}
					/>
				</IntegrationErrorBox>
				<IntegrationErrorFeedback
					type={$type}
					name={integration?.key}
					onAction={onAction}
				/>
			</Content>
			<Footer>
				<FooterSection>
					<Button
						analyticsKey={`integrations.${integration.key}.abort`}
						variant="tertiary"
						label={TranslationService.get(
							[
								'action.abort',
								'button.abort',
								'onboarding.modal.action',
								'onboarding.modal.action.abort',
								'onboarding.modal.flow-error.action',
								'onboarding.modal.flow-error.action.abort',
								`onboarding.modal.${integration?.type}.action`,
								`onboarding.modal.${integration?.type}.action.abort`,
								`onboarding.modal.${integration?.type}.flow-error.action`,
								`onboarding.modal.${integration?.type}.flow-error.action.abort`,
								`onboarding.modal.${integration?.key}.action`,
								`onboarding.modal.${integration?.key}.action.abort`,
								`onboarding.modal.${integration?.key}.flow-error.action`,
								`onboarding.modal.${integration?.key}.flow-error.action.abort`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action.abort`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-error.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-error.action.abort`,
							],
							undefined,
							{ name: integrationName },
						)}
						onClick={abort}
					/>
				</FooterSection>
			</Footer>
		</Step>
	);
};

IntegrationError.displayName = 'IntegrationError';

IntegrationError.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onClose: PropTypes.func,
};

export default IntegrationError;
export { useAnalytics };
