import { createSlice } from '@reduxjs/toolkit';

import Analytics from '@asteria/utils-analytics';

import { ACTIONS } from '../../constants';

export * as selectors from './selectors';

export const MODAL_WINDOWS = {
	ScenarioAbout: 'scenario:about',
	ScenarioCreate: 'scenario:create',
	ScenarioEdit: 'scenario:edit',
	ScenarioRemove: 'scenario:remove',
	ScenarioReset: 'scenario:reset',
	ScenarioClear: 'scenario:clear',
	ForecasterSettings: 'forecaster:settings',
	TagEdit: 'tags:edit',
	TagRemove: 'tags:remove',
	ClientEdit: 'clients:edit',
	ClientRemove: 'clients:remove',
	ClientOverview: 'clients:overview',
	Settings: 'settings',
	Support: 'support',
	BankAccounts: 'bank:accounts',
	Guide: 'guide',
	Currency: 'currency',
	Statement: 'statement',
	InvoiceEdit: 'invoice:edit',

	GraphSpread: 'graph:spread',

	IntegrationMissing: 'integration:missing',
	IntegrationFlow: 'integration:flow',
	IntegrationList: 'integration:list',
	IntegrationConnect: 'integration:connect',
	IntegrationAccessData: 'integration:access-data',

	TransactionsSettings: 'transactions:settings',

	ViewWelcome: 'welcome:overview',
	WelcomeHelp: 'welcome:help',

	AuthProfileChange: 'auth:profile:change',

	CashflowGraphSettings: 'cashflow:graph:settings',
	Conversation: 'conversation',

	Feedback: 'feedback',
};

const initialState = { current: null, history: [] };

export const slice = createSlice({
	name: 'modals',
	initialState: initialState,
	reducers: {
		open: (state, action) => {
			if (
				action?.payload?.skipValidation ||
				Object.values(MODAL_WINDOWS).includes(action?.payload?.type)
			) {
				Analytics.startFlow('modal', {
					name: action?.payload?.type,
				});

				if (state.current) {
					state.history.push(state.current);
				}

				state.current = action.payload;
			}
		},
		close: (state, action) => {
			const size = action?.payload?.size ?? 1;

			if (size > 1) {
				state.history.splice(-(size - 1));
			}

			if (size === Infinity) {
				Analytics.endFlow('modal', true);
			} else {
				for (let index = 0; index < size; index += 1) {
					Analytics.endFlow('modal');
				}
			}

			const payload = state.history.pop() ?? null;
			state.current = payload;
		},
	},
	extraReducers: {
		[ACTIONS.RESET]: () => initialState,
	},
});

// Action creators are generated for each case reducer function
export const { open, close } = slice.actions;

export default slice.reducer;
