import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';
import { Text } from '@asteria/component-core/typography';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import './styles.scss';

const SwitcherChip = React.memo((props) => {
	const { active, type, onClick } = props;

	const handleClick = React.useCallback(
		() => onClick?.(type),
		[onClick, type],
	);

	return (
		<Chip
			size="sm"
			label={TranslationService.get([
				`financial.page.loan.switcher.chip.label`,
				`financial.page.loan.switcher.chip.${type}.label`,
			])}
			active={active}
			onClick={handleClick}
		/>
	);
});

SwitcherChip.displayName = 'SwitcherChip';
SwitcherChip.propTypes = {
	active: PropTypes.bool,
	type: PropTypes.string,
	onClick: PropTypes.func,
};

const Switcher = React.memo((props) => {
	const { active, onAction } = props;

	const onSelect = React.useCallback(
		(view) => onAction?.('view:change', view),
		[onAction],
	);

	return (
		<Group
			className={cn(
				'asteria-component__financial-loan-switcher',
				'asteria-component__chip-group',
			)}
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="center"
		>
			<Text size="sm">
				{TranslationService.get([`financial.page.loan.switcher.label`])}
			</Text>
			{['graph', 'transactions'].map((type) => (
				<SwitcherChip
					key={type}
					active={active === type}
					type={type}
					onClick={onSelect}
				/>
			))}
		</Group>
	);
});

Switcher.displayName = 'Switcher';
Switcher.propTypes = { active: PropTypes.string, onAction: PropTypes.func };

export default Switcher;
