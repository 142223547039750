import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useStateToggle from '@asteria/utils-hooks/useStateToggle';

import PieGraph from '../../../../chart/pie';
import ResponsiveContainer from '../../../components/responsive-container';

import InvoicePieTooltip from './pieTooltip';
import StatusLine from './statusLine';
import Summary from './summary';

import './styles.scss';

const InvoicesContent = (props) => {
	const { query, translationOptions } = props;
	const [clientType, setClientType] = useStateToggle(null);
	const [activeIndex, setActiveIndex] = React.useState(null);

	const values = React.useMemo(() => {
		const dataSource = clientType
			? query?.data?.source?.[clientType] ?? {}
			: query?.data?.source ?? {};

		return Object.entries(dataSource)
			.filter(
				([key, value]) =>
					key !== 'summary' &&
					key !== 'customers' &&
					key !== 'suppliers' &&
					value.total !== 0,
			)
			.map(([key, { count, total }]) => {
				const tag = key === 'credit' ? 'invoices-credit' : key;

				const data = {
					count: count,
					total: total,
					absoluteTotal: Math.abs(total),
				};

				return {
					id: tag,
					...data,
					label: TranslationService.getV2(
						'card.content.type.switch.customer',
						translationOptions,
					),
					fill: 'var(--color)',
					className: `asteria-color__${tag}`,
					tooltip: (
						<InvoicePieTooltip
							translationOptions={translationOptions}
							tag={tag}
							data={data}
							clientType={clientType}
						/>
					),
				};
			});
	}, [query?.data?.source, translationOptions, clientType]);

	return (
		<div className="flex flex-row gap-4 w-full">
			<ResponsiveContainer
				className={cn(
					'flex flex-col grow',
					'asteria-component__card-invoices-content',
				)}
				minWidth={620}
			>
				<Group
					className={cn(
						'asteria-component__card-section',
						'asteria--type-switcher',
						'asteria-component__chip-group',
					)}
					direction="horizontal"
					horizontalAlign="left"
				>
					<Chip
						size="sm"
						label={TranslationService.getV2(
							'card.content.client_type.switch.customers',
							translationOptions,
						)}
						active={clientType === 'customers'}
						onClick={() => setClientType('customers')}
					/>
					<Chip
						size="sm"
						label={TranslationService.getV2(
							'card.content.client_type.switch.suppliers',
							translationOptions,
						)}
						active={clientType === 'suppliers'}
						onClick={() => setClientType('suppliers')}
					/>
				</Group>
				<Summary
					clientType={clientType}
					query={query}
					translationOptions={translationOptions}
				/>
				<div className="asteria-component__tags-wrapper">
					{values.map((value, index) => (
						<StatusLine
							key={index}
							value={value}
							index={index}
							translationOptions={translationOptions}
							clientType={clientType}
							setActive={setActiveIndex}
						/>
					))}
				</div>
				<div
					className={cn(
						'flex flex-col justify-self-center h-full',
						'asteria-component__pie-chart-wrapper',
					)}
				>
					<PieGraph
						data={values}
						dataKey="absoluteTotal"
						activeIndex={activeIndex}
						innerRadius="35%"
					/>
				</div>
			</ResponsiveContainer>
		</div>
	);
};

InvoicesContent.propTypes = {
	query: PropTypes.object,
	translationOptions: PropTypes.shape({
		postfix: PropTypes.object,
	}),
	onAction: PropTypes.func,
};

export default InvoicesContent;
