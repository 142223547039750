import React from 'react';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text, TextGroup, Title } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import { useIntegrationName } from '../../../hooks';
import IntegrationActions from '../../actions';
import FixButton from '../../button-fix';

import Status from './status';

/** @type { React.FC<{ className: string, id: string, flow: unknown, size: 'md' | 'sm', onOpen: (flow: unknown) => void, status: string, id: string }> } */
const IntegrationBox = React.memo(function IntegrationBox(props) {
	const { className, flow, size = 'md', onOpen, status, id } = props;

	const type = flow?.type;
	const key = flow?.key;

	const integrationName = useIntegrationName(type, key);

	const open = React.useCallback(() => onOpen?.(flow), [flow, onOpen]);

	const defaultImageStyles = React.useMemo(
		() => ({
			backgroundImage: `url('${flow?.info?.logo?.default?.path}')`,
			width: 120,
			backgroundPositionY: 'center',
		}),
		[flow?.info?.logo?.default?.path],
	);

	const smallImageStyles = React.useMemo(
		() => ({
			backgroundImage: `url('${flow?.info?.logo?.small?.path}')`,
			backgroundPositionY: 'center',
		}),
		[flow?.info?.logo?.small?.path],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-6 rounded border border-solid border-border-normal relative',
				{ 'cursor-pointer': !status },
				{
					'p-4 md:p-6': size === 'md',
					'p-4': size === 'sm',
				},
				'asteria-component__onboarding-box',
				{
					[`asteria--size-${size}`]: size,
					[`asteria--status-${status}`]: status,
				},

				className,
			)}
			onClick={!id ? open : null}
		>
			<Status {...props} />
			<div className="absolute top-4 right-4 flex gap-2 items-center">
				<FixButton {...props} />
				<IntegrationActions {...props} />
			</div>
			<div
				className={cn(
					'flex justify-between gap-2 items-center',
					'asteria-component__onboarding-box-header',
				)}
			>
				<div
					className={cn('h-10 bg-contain bg-no-repeat', {
						'hidden md:block': size === 'md',
						hidden: size === 'sm',
					})}
					style={defaultImageStyles}
				/>
				<div
					className={cn('gap-2 items-center h-full w-full', {
						'flex md:hidden': size === 'md',
						flex: size === 'sm',
					})}
				>
					<div
						className="w-8 h-8 bg-contain bg-no-repeat"
						style={smallImageStyles}
					/>
					<Translation
						className="flex-1"
						translationKey="onboarding.box.title"
						data={{ integration: integrationName }}
						defaultText="{{{ integration | capitalize }}}"
						translationOptions={{ postfix: { type, key } }}
						Component={Title}
						size="xxs"
					/>
				</div>

				{!id ? <Button icon="chevron-right" onClick={open} /> : null}
			</div>

			<TextGroup
				className={cn(
					'flex-col gap-2',
					{
						'hidden md:flex': size === 'md',
						hidden: size === 'sm',
					},
					'asteria-component__onboarding-box-content',
				)}
			>
				<Translation
					translationKey="onboarding.box.title"
					data={{ integration: integrationName }}
					defaultText="{{{ integration | capitalize }}}"
					translationOptions={{ postfix: { type, key } }}
					Component={Title}
					size="xs"
				/>
				<Translation
					translationKey="onboarding.box.content"
					data={{ integration: integrationName }}
					Component={Text}
					translationOptions={{ postfix: { type, key } }}
				/>
			</TextGroup>
		</div>
	);
});

IntegrationBox.displayName = 'IntegrationBox';

IntegrationBox.propTypes = {
	className: PropTypes.string,
	flow: PropTypes.object,
	size: PropTypes.string,
	onOpen: PropTypes.func,
	variant: PropTypes.string,
	id: PropTypes.string,
	status: PropTypes.string,
};

export default IntegrationBox;
