import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { useMutation } from '@tanstack/react-query';
import { merge } from 'lodash-es';
import PropTypes from 'prop-types';

import Form from '@asteria/component-form';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import Analytics from '@asteria/utils-analytics';

import { useFlow } from '../../hooks';

import IntegrationBasic from './Basic';
import IntegrationCode from './Code';
import IntegrationCustom from './Custom';
import IntegrationDynamic from './Dynamic';
import IntegrationOAuth2 from './OAuth2';

const IntegrationConfigurationContent = (props) => {
	const { type, name } = props;

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const flow = useFlow(type, key);

	if (flow?.flow === 'code') {
		return <IntegrationCode {...props} flow={flow} />;
	}

	if (flow?.flow === 'oAuth') {
		return <IntegrationOAuth2 {...props} flow={flow} />;
	}

	if (flow?.flow === 'basic') {
		return <IntegrationBasic {...props} flow={flow} />;
	}

	if (flow?.flow === 'custom') {
		return <IntegrationCustom {...props} flow={flow} />;
	}

	// TODO: Implement Custom flow

	return <IntegrationDynamic {...props} flow={flow} />;
};

IntegrationConfigurationContent.displayName = 'IntegrationConfigurationContent';

IntegrationConfigurationContent.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,

	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	header: PropTypes.shape({ hideClose: PropTypes.bool }),
	footer: PropTypes.shape({ hideBack: PropTypes.bool }),
};

function formatRecreateRequest(form) {
	// eslint-disable-next-line no-unused-vars
	const { key, type, $id, ...rest } = form;

	return merge({ _id: $id, config: { connected: false } }, { ...rest }, {});
}

const IntegrationConfiguration = (props) => {
	const { type, name, onSubmit, onClose } = props;

	const dispatch = useDispatch();

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.configuration', {
			integrationType: type,
		});

		return () => {
			Analytics.endFlow('integrations.configuration');
		};
	}, []);

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const navigationKey = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store),
	);

	const form = useSelector(IntegrationStore.selectors.form);

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.configuration');
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event, { size: Infinity });
		},
		[dispatch, onClose],
	);

	const abort = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.configuration');
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event);
		},
		[dispatch, onClose],
	);

	const back = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.configuration');

			if (navigationKey) {
				dispatch(IntegrationStore.navigation.back());
			} else {
				abort?.(event);
			}
		},
		[abort, dispatch, navigationKey],
	);

	const next = useMutation({
		mutationFn: async (form) => {
			if (form?.$id) {
				return onSubmit?.(
					'integrations:recreate',
					formatRecreateRequest(form),
				);
			}

			return onSubmit?.('integrations:create', form);
		},
		onSuccess: (data) => {
			const ID = data?._id ?? data?.id;
			dispatch(IntegrationStore.navigation.next({ id: ID }));
		},
	});

	const values = React.useMemo(
		() => ({
			$id: form?.$id,
			type: type,
			key: key,
			config: {
				client: form?.config?.client,
			},
		}),
		[form?.$id, form?.config?.client, key, type],
	);

	return (
		<Form onSubmit={next.mutate} values={values}>
			<IntegrationConfigurationContent
				{...props}
				loading={next.isLoading}
				onClose={close}
				onBack={back}
				onAbort={abort}
			/>
		</Form>
	);
};

IntegrationConfiguration.displayName = 'IntegrationConfiguration';

IntegrationConfiguration.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	name: PropTypes.string,

	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	header: PropTypes.shape({ hideClose: PropTypes.bool }),
	footer: PropTypes.shape({ hideBack: PropTypes.bool }),
};

export default IntegrationConfiguration;
