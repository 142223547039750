import React from 'react';

import { useDispatch, useSelector, useStore } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { FooterSection } from '@asteria/component-core/wrapper';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';

import Step, { Content, Footer, Header } from '../../components/Step';

import IntegrationSuccessBox from './box';
import IntegrationSuccessFeedback from './feedback';
import { useAnalytics } from './hooks';

import './styles.scss';

const IntegrationSuccess = (props) => {
	const { className, onAction, onClose, type: $type, header } = props;

	const dispatch = useDispatch();
	const store = useStore();

	const _id = useSelector(IntegrationStore.selectors.navigation._id);
	const integration = useSelector((store) =>
		IntegrationStore.selectors.integration(store, _id),
	);

	const type = $type ?? integration?.type;
	const key = integration?.key;

	const analyticsFlow = useAnalytics(integration);

	const step = `${type}.success`;

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow(analyticsFlow);
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event, { size: Infinity });
		},
		[analyticsFlow, dispatch, onClose],
	);

	const done = React.useCallback(
		(event) => {
			const shouldOpenList = (
				store.getState()?.modals?.history ?? []
			).some(
				(object) =>
					object?.type ===
					ModalStore.MODAL_WINDOWS.IntegrationConnect,
			);

			close(event);

			if (shouldOpenList) {
				onAction?.('go', '/integrations');
			}
		},
		[close, onAction, store],
	);

	const integrationName = TranslationService.getV2([
		'integration.title',
		`integration.title.${integration?.key}`,
	]);

	return (
		<Step
			className={cn(
				'asteria-component__onboarding-generic__success',
				{
					[`asteria-component__onboarding-generic__success--type-${type}`]:
						type,
				},
				className,
			)}
			step={step}
		>
			<Header onClose={!header?.hideClose ? close : null}>
				{TranslationService.get(
					[
						`integrations.add.title`,
						`integrations.${type}.add.title`,
						`integrations.${key}.add.title`,
						`integrations.${type}.${key}.add.title`,
						`integrations.success.title`,
						`integrations.${type}.success.title`,
						`integrations.${key}.success.title`,
						`integrations.${type}.${key}.success.title`,
					],
					undefined,
					{ integration: integration, name: integrationName },
				)}
			</Header>
			<Content step={step}>
				<IntegrationSuccessBox
					integration={integration}
					integrationName={integrationName}
					type={type}
				/>
				<IntegrationSuccessFeedback
					type={type}
					name={key}
					onAction={onAction}
				/>
			</Content>
			<Footer>
				<FooterSection position="last">
					<Button
						analyticsKey={`integrations.${key}.done`}
						variant="primary"
						label={TranslationService.get(
							[
								'action.done',
								'button.done',
								'onboarding.modal.action',
								'onboarding.modal.action.done',
								'onboarding.modal.flow-success.action',
								'onboarding.modal.flow-success.action.done',
								`onboarding.modal.${integration?.type}.action`,
								`onboarding.modal.${integration?.type}.action.done`,
								`onboarding.modal.${integration?.type}.flow-success.action`,
								`onboarding.modal.${integration?.type}.flow-success.action.done`,
								`onboarding.modal.${integration?.key}.action`,
								`onboarding.modal.${integration?.key}.action.done`,
								`onboarding.modal.${integration?.key}.flow-success.action`,
								`onboarding.modal.${integration?.key}.flow-success.action.done`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.action.done`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-success.action`,
								`onboarding.modal.${integration?.type}.${integration?.key}.flow-success.action.done`,
							],
							undefined,
							{ name: integrationName },
						)}
						onClick={done}
					/>
				</FooterSection>
			</Footer>
		</Step>
	);
};

IntegrationSuccess.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onAction: PropTypes.func,
	onClose: PropTypes.func,

	header: PropTypes.shape({ hideClose: PropTypes.bool }),
};

IntegrationSuccess.defaultProps = {};

export default IntegrationSuccess;
export { IntegrationSuccessFeedback, useAnalytics };
