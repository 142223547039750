import React, { useCallback } from 'react';

import { useDispatch, useStore } from 'react-redux';
import { useLocation } from 'react-router-dom';

import * as FinancialStore from '@asteria/datalayer/stores/financial';
import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

export function useNavigation({ navigate, onForecasterValidate }) {
	const dispatch = useDispatch();
	const store = useStore();

	const location = useLocation();
	const pathnameRef = React.useRef(location.pathname);

	React.useEffect(() => {
		pathnameRef.current = location.pathname;
	}, [location.pathname]);

	return useCallback(
		(path, data) => {
			if (path.startsWith('/forecaster')) {
				return navigate(path);
			}

			if (path.startsWith('/financial')) {
				const parts = path.split('/').filter(Boolean);

				const type = parts?.[1];

				if (type) {
					dispatch(FinancialStore.navigation.go({ type: type }));
				}

				navigate('/financial', data);

				return;
			}

			if (path === '/credit') {
				dispatch(FinancialStore.navigation.go({ type: 'credit' }));
				navigate('/financial/credit', data);

				return;
			}

			if (path.startsWith('/onboarding')) {
				navigate('/onboarding', data);

				return;
			}

			if (path === '/clients') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.ClientOverview,
						data: {
							defaultValues:
								data?.defaultValues ??
								data?.state?.defaultValues,
							values: data?.values ?? data?.state?.values,
						},
					}),
				);
			}

			if (path === '/accounts') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.BankAccounts,
					}),
				);
			}

			if (path === '/welcome') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.ViewWelcome,
					}),
				);
			}

			if (path === '/settings') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.Settings,
					}),
				);
			}

			if (path === '/guide') {
				return dispatch(
					ModalStore.open({ type: ModalStore.MODAL_WINDOWS.Guide }),
				);
			}

			if (path === '/support') {
				return dispatch(
					ModalStore.open({ type: ModalStore.MODAL_WINDOWS.Support }),
				);
			}

			if (path === '/overview') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.Currency,
					}),
				);
			}

			if (path === '/integrations') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationList,
						data: {
							content: {
								before: [
									{
										as: 'Text',
										props: {
											children: `onboarding.list.content.before`,
										},
									},
								],
								between: [
									{
										as: 'Text',
										props: {
											children: `onboarding.list.content.between`,
										},
									},
								],
								after: [
									{
										as: 'Text',
										props: {
											children: `onboarding.list.content.after`,
										},
									},
								],
							},
						},
					}),
				);
			}

			if (path === '/integrations/connect') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationConnect,
					}),
				);
			}

			if (path === '/integrations/add/erp') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationConnect,
						data: { filter: { type: 'erp' } },
					}),
				);
			}

			if (path === '/integrations/add/bank') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.IntegrationConnect,
						data: { filter: { type: 'bank' } },
					}),
				);
			}

			if (path.startsWith('/integrations/edit/')) {
				const [, , , integrationId] = path.split('/');

				const integration = IntegrationStore.selectors.integration(
					store.getState(),
					integrationId,
				);

				if (integration) {
					dispatch(
						IntegrationStore.navigation.go({
							key: integration?.key,
							form: {
								$id: integration?._id ?? integration?.id,
								config: {
									client: integration?.config?.client,
								},
							},
						}),
					);

					dispatch(
						ModalStore.open({
							type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
							data: { type: integration?.type },
						}),
					);
				}

				return;
			}

			if (path === '/statement') {
				return dispatch(
					ModalStore.open({
						type: ModalStore.MODAL_WINDOWS.Statement,
					}),
				);
			}

			if (
				pathnameRef.current === '/forecaster' &&
				!data?.skipValidation
			) {
				onForecasterValidate('/');
			} else {
				navigate('/', data);
			}

			if (path === '/logout') {
				window.localStorage.removeItem('wingsToken');
				window.localStorage.removeItem('wingsRefreshToken');

				const url = new URL('', window.location.origin);
				url.search = window.location.search;

				window.location.replace(url.toString());
			}
		},
		[dispatch, navigate, onForecasterValidate, store],
	);
}
