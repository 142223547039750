import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';

const IntegrationActions = (props) => {
	const { integration, onAction, onSubmit, disabled } = props;

	const dispatch = useDispatch();

	const handleToggle = React.useCallback(() => {
		if (integration.disabled) {
			onSubmit?.('integrations:enable', integration);
		} else {
			onSubmit?.('integrations:disable', integration);
		}
	}, [onSubmit, integration]);

	const handleDelete = React.useCallback(
		() => onAction?.('integrations:action:delete'),
		[onAction],
	);

	const handleFetch = React.useCallback(
		() => onSubmit?.('integrations:import', integration),
		[onSubmit, integration],
	);

	const handleEdit = React.useCallback(() => {
		dispatch(
			IntegrationStore.navigation.go({
				key: integration?.key,
				form: {
					$id: integration?._id ?? integration?.id,
					config: { client: integration?.config?.client },
				},
			}),
		);

		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
				data: { type: integration?.type },
			}),
		);
	}, [
		dispatch,
		integration?._id,
		integration?.config?.client,
		integration?.id,
		integration?.key,
		integration?.type,
	]);

	const ToggleProps = React.useMemo(
		() => ({
			icon: 'more',
			iconActive: 'more',
			disabled,
		}),
		[disabled],
	);

	return (
		<Dropdown
			toggle={ToggleProps}
			title={TranslationService.get([
				'integrations.actions.title',
				'integrations.actions.dropdown.title',
			])}
			analyticsKey="integration.options"
		>
			<DropdownItem
				onClick={handleDelete}
				analyticsKey="integration.options.item.delete"
			>
				{TranslationService.get(
					[
						'integrations.list.action.delete',
						`integrations.list.action.${integration?.name}.delete`,
					],
					undefined,
					integration,
				)}
			</DropdownItem>
			<FeatureFlag feature="integrations-edit">
				<DropdownItem
					onClick={handleEdit}
					analyticsKey="integration.options.item.edit"
				>
					{TranslationService.get(
						[
							'integrations.list.action.edit',
							`integrations.list.action.${integration?.name}.edit`,
						],
						undefined,
						integration,
					)}
				</DropdownItem>
			</FeatureFlag>
			<DropdownItem
				onClick={handleToggle}
				analyticsKey="integration.options.item.toggle"
			>
				{integration?.disabled
					? TranslationService.get(
							[
								'integrations.list.action.enable',
								`integrations.list.action.${integration?.name}.enable`,
							],
							undefined,
							integration,
					  )
					: TranslationService.get(
							[
								'integrations.list.action.disable',
								`integrations.list.action.${integration?.name}.disable`,
							],
							undefined,
							integration,
					  )}
			</DropdownItem>
			{!integration?.disabled ? (
				<DropdownItem
					onClick={handleFetch}
					analyticsKey="integration.options.item.fetch"
				>
					{TranslationService.get(
						[
							'integrations.list.action.fetch',
							`integrations.list.action.${integration?.name}.fetch`,
						],
						undefined,
						integration,
					)}
				</DropdownItem>
			) : null}
		</Dropdown>
	);
};

IntegrationActions.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,

	integration: PropTypes.shape({
		_id: PropTypes.string,
		id: PropTypes.string,
		name: PropTypes.string,
		key: PropTypes.string,
		type: PropTypes.string,
		state: PropTypes.string,
		status: PropTypes.shape({
			state: PropTypes.string,
		}),
		lastSync: PropTypes.string,

		disabled: PropTypes.bool,

		config: PropTypes.shape({
			client: PropTypes.object,
			connected: PropTypes.bool,
		}),
	}),

	disabled: PropTypes.bool,
};

export default IntegrationActions;
