import React from 'react';

import PropTypes from 'prop-types';

import Group from '@asteria/component-core/group';

import Chip from '@asteria/component-chip';

import { TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

/** @type { React.FC<{ active: boolean, type: 'customers' | 'suppliers', onSelect: (type: 'CUSTOMER' | 'SUPPLIER') => void }> } */
const SelectorChip = React.memo(function SelectorChip(props) {
	const { active, type, onSelect } = props;

	const onClick = React.useCallback(() => {
		if (type === 'customers') {
			return onSelect?.('CUSTOMER');
		}

		if (type === 'suppliers') {
			return onSelect?.('SUPPLIER');
		}
	}, [onSelect, type]);

	return (
		<Chip
			size="sm"
			label={TranslationService.getV2('card.content.client.switch', {
				postfix: { type: type },
			})}
			active={active}
			onClick={onClick}
		/>
	);
});

SelectorChip.propTypes = {
	active: PropTypes.bool,
	type: PropTypes.string,
	onSelect: PropTypes.func,
};

/** @type { React.FC<{ active: string, onSelect: (type: string) => void }> } */
const Selector = React.memo(function Selector(props) {
	const { active, onSelect } = props;

	return (
		<Group
			className={cn(
				'asteria-component__card-section',
				'asteria--type-switcher',
				'asteria-component__chip-group',
			)}
			direction="horizontal"
			verticalAlign="center"
			horizontalAlign="left"
		>
			<SelectorChip
				active={active === 'CUSTOMER'}
				type="customers"
				onSelect={onSelect}
			/>
			<SelectorChip
				active={active === 'SUPPLIER'}
				type="suppliers"
				onSelect={onSelect}
			/>
		</Group>
	);
});

Selector.propTypes = { active: PropTypes.string, onSelect: PropTypes.func };

export default Selector;
