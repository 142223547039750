import React from 'react';

import Analytics from '@asteria/utils-analytics';

function useAnalytics(integration) {
	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.error', {
			integration: {
				_id: integration?._id,
				key: integration?.key,
				type: integration?.type,
				lastSync: integration?.lastSync,
				disabled: integration?.disabled,
				status: integration?.status,
				config: {
					connected: integration?.config?.connected,
					errors: integration?.config?.errors,
				},
				actions: integration?.actions,
			},
		});

		return () => {
			Analytics.endFlow('integrations.error');
		};
	}, []);

	return 'integrations.error';
}

export { useAnalytics };
