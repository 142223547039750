import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

import IntegrationBox from '../box';
import { SearchWrapper } from '../search';

import './styles.scss';

const Connect = React.memo(function Connect(props) {
	const { className, type, onClose } = props;

	const dispatch = useDispatch();

	const onOpen = React.useCallback(
		(flow) => {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationAccessData,
					data: { integration: flow },
				}),
			);
		},
		[dispatch],
	);

	return (
		<Wrapper
			className={cn('asteria-component__onboarding-connect', className)}
		>
			<Header onClose={onClose}>
				{TranslationService.getV2(['onboarding.modal.title'], {
					postfix: { variant: 'connect' },
				})}
			</Header>
			<Content>
				<div className="flex flex-col gap-4">
					<Translation
						translationKey="onboarding.modal.content"
						translationOptions={{
							postfix: { variant: 'connect', type: type },
						}}
						Component={Text}
						size="sm"
					/>

					<SearchWrapper size="sm">
						{({ integrations }) => (
							<div
								className={cn(
									'overflow-x-auto pr-2',
									'asteria-component__onboarding-connect-list',
								)}
							>
								{integrations?.length ? (
									<div className="grid gap-4">
										{integrations?.map((object) => (
											<IntegrationBox
												key={[
													object?.type,
													object?.key,
												].join('-')}
												flow={object}
												size="sm"
												onOpen={onOpen}
											/>
										))}
									</div>
								) : (
									<div className="pt-10 text-center">
										<Translation
											translationKey="onboarding.modal.content.placeholder"
											translationOptions={{
												postfix: {
													variant: 'connect',
													type: type,
												},
											}}
											Component={Text}
										/>
									</div>
								)}
							</div>
						)}
					</SearchWrapper>
				</div>
			</Content>
			<Footer>
				<FooterSection position="last">
					<Button
						variant="tertiary"
						onClick={onClose}
						label={TranslationService.getV2(
							['onboarding.modal.action'],
							{
								postfix: {
									variant: 'connect',
									action: 'close',
								},
							},
						)}
						analyticsKey="onboarding.modal.connect.action.close"
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

Connect.propTypes = {
	className: PropTypes.string,
	type: PropTypes.string,
	filter: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const ConnectModal = React.memo(function ConnectModal(props) {
	const { className, open, onClose } = props;

	return (
		<Modal
			className={cn(
				'asteria-component__onboarding-modal',
				'asteria--variant-connect',
				className,
			)}
			open={open}
			onClose={onClose}
			size="sm"
		>
			<Connect {...props} />
		</Modal>
	);
});

ConnectModal.propTypes = {
	className: PropTypes.string,
	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default Connect;
export { ConnectModal };
