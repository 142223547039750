import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import PageHeader, {
	PageContent,
	PagePostfix,
	PageSubtitle,
	PageTitle,
} from '@asteria/component-core/pageHeader';
import { Text, Title } from '@asteria/component-core/typography';

import IntegrationBox, {
	StatusIntegrationBox,
} from '@asteria/component-integrations-v2/components/box';
import { SearchWrapper as OnboardingSearchWrapper } from '@asteria/component-integrations-v2/components/search';
import Notifications from '@asteria/component-notifications';
import { FeatureFlag } from '@asteria/component-tools/featureflag';
import { TourButton } from '@asteria/component-tour';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';

const Connected = React.memo(function Connected(props) {
	const { onAction, onSubmit, onOpen } = props;

	const integrations = useSelector(
		IntegrationStore.selectors.integrations,
		isEqual,
	);

	if (!integrations.length) {
		return null;
	}

	return (
		<div className="flex flex-col gap-4">
			<Translation
				translationKey="view.integrations.section.title"
				translationOptions={{
					postfix: { section: 'connected' },
					data: { count: integrations.length },
				}}
				Component={Title}
				size="xs"
			/>
			<div className="grid md:grid-cols-2 gap-4">
				{integrations.map((object) => (
					<StatusIntegrationBox
						key={object?._id ?? object?.id}
						id={object?._id ?? object?.id}
						onAction={onAction}
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="status"
					/>
				))}
			</div>
		</div>
	);
});

Connected.propTypes = {
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

const Available = React.memo(function Available(props) {
	const { integrations, onAction, onSubmit, onOpen } = props;

	const exists = useSelector(
		(store) => !!IntegrationStore.selectors.integrations(store).length,
	);

	return (
		<div className="flex flex-col gap-4">
			{exists ? (
				<Translation
					translationKey="view.integrations.section.title"
					translationOptions={{ postfix: { section: 'available' } }}
					Component={Title}
					size="xs"
				/>
			) : null}

			<div className="grid md:grid-cols-2 gap-4">
				{integrations.map((object) => (
					<IntegrationBox
						key={[object?.type, object?.key].join('-')}
						flow={object}
						onAction={onAction}
						onSubmit={onSubmit}
						onOpen={onOpen}
						variant="connect"
					/>
				))}
			</div>
		</div>
	);
});

Available.propTypes = {
	integrations: PropTypes.arrayOf(PropTypes.object),
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
	onOpen: PropTypes.func,
};

const OnboardingView = React.memo(function OnboardingView(props) {
	const { className, onAction, onSubmit } = props;

	const dispatch = useDispatch();

	const onOpen = React.useCallback(
		(flow) => {
			dispatch(
				ModalStore.open({
					type: ModalStore.MODAL_WINDOWS.IntegrationAccessData,
					data: { integration: flow },
				}),
			);
		},
		[dispatch],
	);

	return (
		<div
			className={cn(
				'flex flex-col gap-4',
				'asteria-view__onboarding',
				className,
			)}
		>
			<PageHeader>
				<PageContent>
					<PageTitle
						title={TranslationService.get(
							'view.integrations.title',
							// eslint-disable-next-line spellcheck/spell-checker
							'Bokföringssystem',
						)}
					/>
					<PageSubtitle>
						<Translation
							translationKey="view.integrations.sub-title"
							Component={Text}
						/>
					</PageSubtitle>
				</PageContent>
				<PagePostfix>
					<FeatureFlag feature="widget-notifications">
						<FeatureFlag feature="widget-notifications-onboarding">
							<Notifications onAction={onAction} />
						</FeatureFlag>
					</FeatureFlag>
					<FeatureFlag feature="page-header-tour">
						<TourButton />
					</FeatureFlag>
				</PagePostfix>
			</PageHeader>
			<OnboardingSearchWrapper onAction={onAction} onSubmit={onSubmit}>
				{({ integrations }) => (
					<div className="flex flex-col gap-8">
						<Connected
							onAction={onAction}
							onSubmit={onSubmit}
							onOpen={onOpen}
						/>
						<Available
							integrations={integrations}
							onAction={onAction}
							onSubmit={onSubmit}
							onOpen={onOpen}
						/>
					</div>
				)}
			</OnboardingSearchWrapper>
		</div>
	);
});

OnboardingView.displayName = 'OnboardingView';

OnboardingView.propTypes = {
	className: PropTypes.string,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default OnboardingView;
