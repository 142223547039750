import React from 'react';

import { useSelector } from 'react-redux';

import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { useFlow } from '../../../hooks';
import IntegrationBox from '../basic';
import { useIntegrationStatus } from '../hooks';

/** @type { typeof IntegrationBox & { React.FC<{ id: string }> } } */
const StatusIntegrationBox = React.memo(function StatusIntegrationBox(props) {
	const { id } = props;

	const integration = useSelector(
		(store) => IntegrationStore.selectors.integration(store, id),
		isEqual,
	);

	const status = useIntegrationStatus(id);

	const type = integration?.type;
	const key = integration?.key ?? integration?.name;

	const flow = useFlow(type, key);

	if (!integration) {
		return null;
	}

	return <IntegrationBox {...props} flow={flow} status={status} />;
});

StatusIntegrationBox.propTypes = {
	id: PropTypes.string,
};

export default StatusIntegrationBox;
