import React from 'react';

import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';

import Accordion, { AccordionItem } from '@asteria/component-core/accordion';
import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import Wrapper, {
	Content,
	Footer,
	FooterSection,
	Header,
} from '@asteria/component-core/wrapper';

import Modal from '@asteria/component-modal';
import Contenter from '@asteria/component-tools/contenter';

import * as ModalStore from '@asteria/datalayer/stores/modals';

import { Translation, TranslationService } from '@asteria/language';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import './styles.scss';

const AccessData = React.memo(function AccessDataModal(props) {
	const { className, integration, onClose } = props;

	const dispatch = useDispatch();

	const close = React.useCallback(
		(event) => onClose?.(event, { size: Infinity }),
		[onClose],
	);

	const {
		info: { content },
	} = useConfig(['integrations.access'], {
		deep: true,
	});

	const handleConnectClick = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.IntegrationFlow,
				data: { type: integration?.type, name: integration?.key },
			}),
		);
	}, [dispatch, integration?.key, integration?.type]);

	return (
		<Wrapper
			className={cn(
				'asteria-component__onboarding-access-data',
				className,
			)}
		>
			<Header onClose={close} onBack={onClose}>
				{TranslationService.getV2(['onboarding.modal.title'], {
					postfix: { variant: 'access-data' },
				})}
			</Header>
			<Content>
				<div className="flex flex-col gap-4">
					<Contenter
						content={content}
						translationData={{ data: { integration: integration } }}
					/>
					<Accordion>
						<AccordionItem
							label={TranslationService.getV2(
								'integrations.access.data.info.accordion.title',
							)}
						>
							<div className="flex flex-col gap-4">
								<Translation
									translationKey="integrations.access.data.info.accordion.text.1"
									Component={Text}
								/>
								<Translation
									translationKey="integrations.access.data.info.accordion.text.2"
									Component={Text}
								/>
								<div>
									<Button
										size="sm"
										variant="link"
										href="https://www.asteria.ai/sv/data-processing-agreement-sv/"
										label={TranslationService.getV2(
											'integrations.access.data.info.accordion.link.label',
										)}
									/>
								</div>
							</div>
						</AccordionItem>
					</Accordion>
				</div>
			</Content>
			<Footer>
				<FooterSection position="first">
					<Button
						variant="tertiary"
						onClick={close}
						label={TranslationService.getV2(
							['onboarding.modal.action'],
							{
								postfix: {
									variant: 'access-data',
									action: 'close',
								},
							},
						)}
						analyticsKey="onboarding.modal.access-data.action.close"
					/>
				</FooterSection>
				<FooterSection position="last">
					<Button
						variant="primary"
						onClick={handleConnectClick}
						label={TranslationService.getV2(
							['onboarding.modal.action'],
							{
								postfix: {
									variant: 'access-data',
									action: 'next',
								},
							},
						)}
						analyticsKey="onboarding.modal.access-data.action.close"
					/>
				</FooterSection>
			</Footer>
		</Wrapper>
	);
});

AccessData.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	integration: PropTypes.object,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const AccessDataModal = React.memo(function AccessDataModal(props) {
	const { className, open, onClose } = props;

	const close = React.useCallback(
		(event) => onClose?.(event, { size: Infinity }),
		[onClose],
	);

	return (
		<Modal
			className={cn(
				'asteria-component__onboarding-modal',
				'asteria--variant-access-data',
				className,
			)}
			open={open}
			onClose={close}
			size="sm"
		>
			<AccessData {...props} />
		</Modal>
	);
});

AccessDataModal.propTypes = {
	open: PropTypes.bool,
	className: PropTypes.string,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default AccessDataModal;
