import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const IntegrationSuccessBox = React.memo(function IntegrationSuccessBox(props) {
	const { integration, integrationName, type } = props;

	return (
		<div className="flex flex-col bg-alert-success-normal-background gap-2 p-4 rounded-lg">
			<Text size="md" align="center">
				{TranslationService.get(
					[
						`integrations.success.subtitle`,
						`integrations.${type}.success.subtitle`,
						`integrations.${type}.${integration?.key}.success.subtitle`,
					],
					undefined,
					{ integration: integration, name: integrationName },
				)}
			</Text>
			<Title
				size="md"
				className="asteria-component__alert--highlight"
				align="center"
			>
				{TranslationService.get(
					[
						`integrations.success.integration`,
						`integrations.${type}.success.integration`,
						`integrations.${type}.${integration?.key}.success.integration`,
					],
					'{{ name | capitalize }}',
					{ integration: integration, name: integrationName },
				)}
			</Title>
		</div>
	);
});

IntegrationSuccessBox.propTypes = {
	type: PropTypes.string,
	integration: PropTypes.object,
	integrationName: PropTypes.string,
	error: PropTypes.shape({
		code: PropTypes.number,
		message: PropTypes.string,
	}),
};

export default IntegrationSuccessBox;
