import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import './styles.scss';

const IntegrationErrorDetails = React.memo(function IntegrationErrorDetails(
	props,
) {
	const { integration, error, type } = props;

	const code = error?.code ?? 500;

	return (
		<div className="flex flex-row justify-center gap-1">
			<Text size="md">
				{TranslationService.getV2([`integration.error.code`], {
					data: { error: error, code: code },
					postfix: {
						integration: integration?.key,
						code: `${code}`,
						type: type,
					},
				})}
			</Text>
			<Text size="md">
				{TranslationService.getV2([`integration.error.message`], {
					data: { error: error, code: code, message: error?.message },
					postfix: {
						integration: integration?.key,
						code: `${code}`,
						type: type,
					},
				})}
			</Text>
		</div>
	);
});

IntegrationErrorDetails.propTypes = {
	type: PropTypes.string,
	integration: PropTypes.object,
	error: PropTypes.shape({
		code: PropTypes.number,
		message: PropTypes.string,
	}),
};

export default IntegrationErrorDetails;
