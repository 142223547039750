import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Button from '@asteria/component-core/button';
import { Text } from '@asteria/component-core/typography';
import { FooterSection } from '@asteria/component-core/wrapper';

import Form, { Wrapper as FormWrapper } from '@asteria/component-form';
import Select, { Option } from '@asteria/component-form/select';
import {
	Service as FeatureService,
	useBulkFeatures,
} from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';
import * as ModalStore from '@asteria/datalayer/stores/modals';

import { TranslationService } from '@asteria/language';
import Analytics from '@asteria/utils-analytics';
import { cn } from '@asteria/utils-funcs/classes';
import useConfig from '@asteria/utils-hooks/useConfig';

import Step, {
	Content,
	Footer,
	Header,
	StepInner,
} from '../../components/Step';

const IntegrationSelect = (props) => {
	const { className, onClose, type } = props;

	const dispatch = useDispatch();

	const hasHistory = useSelector(ModalStore.selectors.hasHistory);

	const integrations = useConfig(`integrations`, {
		callback: React.useCallback(
			(values) =>
				(values ?? []).filter(
					(object) =>
						object?.type === type &&
						(!object?.feature ||
							FeatureService.isActive(object?.feature)),
				),
			[type],
		),
	});

	const initialValue = useConfig('integrations.options', {
		callback: React.useCallback(
			(options) => options?.[type]?.value,
			[type],
		),
	});

	const hasMissingFeature = useBulkFeatures([
		[
			'onboarding-missing-integration',
			`onboarding-missing-integration-${type}`,
		],
	]);

	React.useLayoutEffect(() => {
		Analytics.startFlow('integrations.select');

		return () => {
			Analytics.endFlow('integrations.select');
		};
	}, []);

	const handleOpenMissingModal = React.useCallback(() => {
		dispatch(
			ModalStore.open({
				type: ModalStore.MODAL_WINDOWS.IntegrationMissing,
				data: { type: type },
			}),
		);
	}, [dispatch, type]);

	const tourKey = React.useMemo(
		() => ['onboarding:select', `onboarding:select:${type}`],
		[type],
	);

	const back = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.select');
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event);
		},
		[dispatch, onClose],
	);

	const abort = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.select');
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event);
		},
		[dispatch, onClose],
	);

	const close = React.useCallback(
		(event) => {
			Analytics.endFlow('integrations.select');
			dispatch(IntegrationStore.navigation.reset());

			return onClose?.(event, { size: Infinity });
		},
		[dispatch, onClose],
	);

	const next = React.useCallback(
		(form) => {
			Analytics.endFlow('integrations.select');
			dispatch(IntegrationStore.navigation.next({ key: form?.key }));
		},
		[dispatch],
	);

	return (
		<Form onSubmit={next}>
			<Step
				className={cn(
					'asteria-component__onboarding-select',
					{
						[`asteria-component__onboarding-select--type-${type}`]:
							type,
					},
					className,
				)}
				step={`${type}.select`}
			>
				<Header
					onBack={hasHistory ? back : null}
					onClose={close}
					tourKey={tourKey}
				>
					{TranslationService.get([
						`integrations.add.title`,
						`integrations.${type}.add.title`,
						`integrations.select.title`,
						`integrations.${type}.select.title`,
					])}
				</Header>
				<Content step={`${type}.select`} scroll>
					<StepInner>
						<Text size="sm">
							{TranslationService.get([
								`integrations.select.label`,
								`integrations.${type}.select.label`,
							])}
						</Text>

						<FormWrapper>
							<Content>
								<Select
									name="key"
									icon="triangle-down"
									iconActive="triangle-up"
									scroll
									strict
									value={initialValue}
									className="asteria-component__onboarding-select__selector"
								>
									{integrations.map((item) => (
										<Option key={item.key} value={item.key}>
											{TranslationService.get(
												[
													'integrations.select.integration',
													`integrations.select.integration.${type}`,
													`integrations.select.integration.${item.key}`,
													`integrations.select.integration.${type}.${item.key}`,
												],
												item.name,
												{ item: item },
											)}
										</Option>
									))}
								</Select>
							</Content>
						</FormWrapper>
					</StepInner>

					{hasMissingFeature ? (
						<Button
							analyticsKey="integrations.select.missing"
							className="asteria-component__onboarding-step__help"
							variant="assist"
							label={TranslationService.get([
								`integrations.missing`,
								`integrations.${type}.missing`,
							])}
							// icon="triangle-right"
							// iconPosition="last"
							onClick={handleOpenMissingModal}
							type="button"
						/>
					) : null}
				</Content>
				<Footer>
					<FooterSection>
						<Button
							analyticsKey="integrations.select.abort"
							variant="tertiary"
							label={TranslationService.get([
								'action.abort',
								'button.abort',
								'onboarding.modal.action',
								'onboarding.modal.action.abort',
								'onboarding.modal.flow-select.action',
								'onboarding.modal.flow-select.action.abort',
								`onboarding.modal.${type}.action`,
								`onboarding.modal.${type}.action.abort`,
								`onboarding.modal.${type}.flow-select.action`,
								`onboarding.modal.${type}.flow-select.action.abort`,
							])}
							onClick={abort}
						/>
					</FooterSection>
					<FooterSection position="last">
						<Button
							analyticsKey="integrations.select.next"
							variant="primary"
							label={TranslationService.get([
								'action.next',
								'button.next',
								'onboarding.modal.action',
								'onboarding.modal.action.next',
								'onboarding.modal.flow-select.action',
								'onboarding.modal.flow-select.action.next',
								`onboarding.modal.${type}.action`,
								`onboarding.modal.${type}.action.next`,
								`onboarding.modal.${type}.flow-select.action`,
								`onboarding.modal.${type}.flow-select.action.next`,
							])}
							type="submit"
						/>
					</FooterSection>
				</Footer>
			</Step>
		</Form>
	);
};

IntegrationSelect.propTypes = {
	type: PropTypes.string,
	className: PropTypes.string,
	onClose: PropTypes.func,
};

IntegrationSelect.defaultProps = {};

export default IntegrationSelect;
