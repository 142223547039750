import React from 'react';

import PropTypes from 'prop-types';

import { Text, Title } from '@asteria/component-core/typography';

import { TranslationService } from '@asteria/language';

import IntegrationErrorDetails from './details';

import './styles.scss';

const IntegrationErrorBoxContent = React.memo(
	function IntegrationErrorBoxContent(props) {
		const { integration, integrationName, type } = props;

		return (
			<div className="flex flex-col gap-2">
				<Text size="md" align="center">
					{TranslationService.get(
						[
							`integrations.error.subtitle`,
							`integrations.${type}.error.subtitle`,
							`integrations.${type}.${integration.key}.error.subtitle`,
							`integrations.error.alert.subtitle`,
							`integrations.${type}.error.alert.subtitle`,
							`integrations.${type}.${integration.key}.error.alert.subtitle`,
						],
						undefined,
						{
							integration: integration,
							name: integrationName,
						},
					)}
				</Text>
				<Title
					className="asteria-component__alert--highlight"
					align="center"
				>
					{TranslationService.get(
						[
							`integrations.error.integration`,
							`integrations.${type}.error.integration`,
							`integrations.${type}.${integration.key}.error.integration`,
							`integrations.error.alert.integration`,
							`integrations.${type}.error.alert.integration`,
							`integrations.${type}.${integration.key}.error.alert.integration`,
						],
						'{{ name | cap }}',
						{
							integration: integration,
							name: integrationName,
						},
					)}
				</Title>
				{integration?.config?.errors?.length
					? integration?.config?.errors.map((error) => (
							<IntegrationErrorDetails
								key={[error.code, error.message].join('-')}
								integration={integration}
								error={error}
								type={type}
							/>
					  ))
					: null}
			</div>
		);
	},
);

IntegrationErrorBoxContent.propTypes = {
	type: PropTypes.string,
	integration: PropTypes.object,
	integrationName: PropTypes.string,
	error: PropTypes.shape({
		code: PropTypes.number,
		message: PropTypes.string,
	}),
};

export default IntegrationErrorBoxContent;
