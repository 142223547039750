import React from 'react';

import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Dropdown, { DropdownItem } from '@asteria/component-core/dropdown';

import { FeatureFlag } from '@asteria/component-tools/featureflag';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import { TranslationService } from '@asteria/language';

const SkipButton = React.memo((props) => {
	const { type, name } = props;

	const dispatch = useDispatch();

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const handleSkipSuccess = React.useCallback(() => {
		dispatch(IntegrationStore.navigation.next({ id: '__SUCCESS__' }));
	}, [dispatch]);

	const handleSkipError = React.useCallback(() => {
		dispatch(IntegrationStore.navigation.next({ id: '__ERROR__' }));
	}, [dispatch]);

	const ToggleProps = React.useMemo(
		() => ({ variant: 'primary', icon: 'cog' }),
		[],
	);

	return (
		<FeatureFlag feature="integrations-skip">
			<Dropdown toggle={ToggleProps} analyticsKey="integration.devtools">
				<DropdownItem
					onClick={handleSkipSuccess}
					analyticsKey="integration.devtools.item"
					analytics={{ key: key, type: 'skip' }}
				>
					{TranslationService.get(
						[
							'action.skip',
							'integrations.action.skip',
							`integrations.action.skip.${key}`,
						],
						'Skip (success)',
						{},
					)}
				</DropdownItem>
				<DropdownItem
					onClick={handleSkipError}
					analyticsKey="integration.devtools.item"
					analytics={{ key: key, type: 'error' }}
				>
					{TranslationService.get(
						[
							'action.skip',
							'integrations.action.skip',
							`integrations.action.skip.${key}`,
							'action.skip.error',
							'integrations.action.skip.error',
							`integrations.action.skip.error.${key}`,
						],
						'Skip (error)',
						{},
					)}
				</DropdownItem>
			</Dropdown>
		</FeatureFlag>
	);
});

SkipButton.displayName = 'SkipButton';

SkipButton.propTypes = { type: PropTypes.string, name: PropTypes.string };

export default SkipButton;
