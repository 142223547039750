const go = (state, { payload }) => {
	if (payload?.key !== undefined) {
		state.navigation.key = payload?.key;
	}

	if (payload?._id !== undefined) {
		state.navigation._id = payload?._id;
	}

	if (payload?.form !== undefined) {
		state.$form = payload?.form;
	}
};

const next = (state, { payload }) => {
	state.$form = null;

	if (payload?.key) {
		state.navigation.key = payload.key;
	}

	if (payload?.id) {
		state.navigation._id = payload.id;
	}
};

const back = (state, { payload }) => {
	if (payload) {
		state.$form = payload;
	}

	if (state.navigation._id) {
		state.navigation._id = null;
	} else if (state.navigation.key) {
		state.navigation.key = null;
	}
};

const reset = (state) => {
	state.$form = null;

	state.navigation.key = null;
	state.navigation._id = null;
};

export {
	go as navigationGo,
	next as navigationNext,
	back as navigationBack,
	reset as navigationReset,
};
