import React from 'react';

import PropTypes from 'prop-types';

import { Text } from '@asteria/component-core/typography';

import { Translation } from '@asteria/language';

const StatusText = React.memo(function StatusText({ translationOptions }) {
	return (
		<Translation
			translationKey="onboarding.box.status"
			translationOptions={translationOptions}
			Component={Text}
		/>
	);
});

StatusText.propTypes = { translationOptions: PropTypes.object };

export default StatusText;
