import React from 'react';

import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';

import Modal from '@asteria/component-modal';

import * as IntegrationStore from '@asteria/datalayer/stores/integrations';

import IntegrationConfiguration from './Configuration';
import IntegrationConnecting from './Connecting';
import IntegrationSelect from './Select';

const IntegrationFlow = (props) => {
	const { open, type, name } = props;

	const key = useSelector((store) =>
		IntegrationStore.selectors.navigation.key(store, {
			type: type,
			key: name,
		}),
	);

	const _id = useSelector(IntegrationStore.selectors.navigation._id);

	if (!open) {
		return null;
	}

	if (_id) {
		return <IntegrationConnecting {...props} />;
	}

	if (key) {
		return <IntegrationConfiguration {...props} />;
	}

	return <IntegrationSelect {...props} />;
};

IntegrationFlow.displayName = 'IntegrationFlow';

IntegrationFlow.propTypes = {
	open: PropTypes.bool,
	type: PropTypes.string,
	name: PropTypes.string,

	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

const IntegrationFlowModal = (props) => {
	const { open, onClose } = props;

	const close = React.useCallback(
		(event) => onClose?.(event, { size: Infinity }),
		[onClose],
	);

	return (
		<Modal open={open} onClose={close} size="sm">
			<IntegrationFlow {...props} />
		</Modal>
	);
};

IntegrationFlowModal.displayName = 'IntegrationFlowModal';

IntegrationFlowModal.propTypes = {
	type: PropTypes.string,

	open: PropTypes.bool,
	onClose: PropTypes.func,
	onAction: PropTypes.func,
	onSubmit: PropTypes.func,
};

export default IntegrationFlow;
export { IntegrationFlowModal };
